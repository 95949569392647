$(function () {

  /* ヘッダー　サブメニュー表示 */
  let target = '';

  $('.hover-action').on({
    'mouseenter': function () {
      let data = $(this).data('menu');
      target = '#' + data;
      $(target).show();

    },
    'mouseleave': function () {
      $('.p-header__nav__list__sub').hide();
    }
  })

  var state = false;
  var pos;

  $('.p-header__hamburger').on('click touchstart', function (event) {

    event.preventDefault();
    var target = $(this).data('target');

    $('.p-header__hamburger__item').toggleClass('active');
    $('.p-header__sp-nav').slideToggle();

    if (target !== 'en') {
      if (state == false) {
        pos = $(window).scrollTop();
        $('body').addClass('fixed').css({ 'top': - pos });
        state = true;
      } else {
        $('body').removeClass('fixed').css({ 'top': 0 });
        window.scrollTo(0, pos);
        state = false;
      }
    }
  });

  $('.p-header__sp-nav__list__item__link--parent').on('click touchstart', function (event) {
    event.preventDefault();
    $(this).next().slideToggle();
    $(this).toggleClass('active');
  })

  $('.p-header__top__parts__search').on('click touchstart', function (event) {
    event.preventDefault();
    $('.p-search--header').slideToggle(300);
  })

  $('.p-search__cross').on('click touchstart', function (event) {
    event.preventDefault();
    $('.p-search--header').slideUp(300);
  })
})